<template>
  <Layout :tituloPagina="`Hotspot | Layouts | Portal cautivo | ${modo == 'nuevo' ? 'Nuevo' : 'Edición'}`">
    <div class="row">
      <div class="col-lg-8">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">
              Vista previa
            </h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      onclick="return false"
                      @click="modo == 'nuevo' ? guardar() : actualizar()"
                      v-if="!bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="cerrar()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body m-b-sm tamano-vista" style="text-align: center; min-height: 1007px;">
            <div style="background-color: white;">
              <div id="portalCautivo" :style="`font-family: '${portal_cautivo.portal.tipo_fuente}'`">
                <div class="wrapper-content">
                  <div class="card" >
                    <div
                      v-if="portal_cautivo.portal.tipo_fondo == 1"
                      class="card-body"
                      :style="portal_cautivo.portal.tipo_fondo == 1 ?`min-height: 250px; border:none;background:${portal_cautivo.portal.primer_color}`:''">
                    </div>
                    <div class="card-body"
                      :style="
                        portal_cautivo.portal.tipo_fondo != 2 ?
                        ` min-height: ${portal_cautivo.portal.tipo_fondo == 3 ? '1050':'800'}px;
                          border:none;
                          ${portal_cautivo.portal.tipo_fondo == 3 ? `
                            background-image: url(${portal_cautivo.portal.fondo_cargado ? `${API}/hotspot/portales-cautivos/${portal_cautivo.id}/imagenes/fondo?_tk=${tk}` : img_fondo});
                            background-size: cover;
                            background-repeat:no-repeat;
                            background-position: center center;
                          `:`
                            background: -webkit-linear-gradient(${portal_cautivo.portal.segundo_color}, ${portal_cautivo.portal.segundo_color});
                          `}
                        `:
                        ` min-height: 1050px;
                          background: -webkit-linear-gradient(${portal_cautivo.portal.primer_color}, ${portal_cautivo.portal.segundo_color});
                        `
                      "
                    >
                      <center >
                        <div
                          v-if="
                            portal_cautivo.portal.mostrar_informacion &&
                            portal_cautivo.portal.posicion_informacion_adicional == 1
                          "
                          v-html="portal_cautivo.portal.informacion_adicional"
                          style="width: 500px; margin-top: 50px;"
                        ></div>
                        <div
                          v-if="
                            portal_cautivo.portal.mostrar_imagen_personalizada &&
                            portal_cautivo.portal.posicion_imagen_personalizada == 1
                          "
                          style="width: 500px; margin-top: 50px;"
                        >
                          <img
                            v-if="!portal_cautivo.portal.imagen_personalizada_cargado"
                            :src="img_person"
                            alt="foto"
                            :style="`width: ${portal_cautivo.portal.tamano_imagen_personaliza}%;`"
                          />

                          <img
                            v-if="portal_cautivo.portal.imagen_personalizada_cargado"
                            :src="`${API}/hotspot/portales-cautivos/${portal_cautivo.id}/imagenes/imagen_personalizada?_tk=${tk}`"
                            alt="foto"
                            :style="`width: ${portal_cautivo.portal.tamano_imagen_personaliza}%;`"
                          />
                        </div>
                      </center>
                      
                      <div
                        id="contenedorFormulario"
                        class="card-body"
                        style="
                          min-height: 500px;
                          width: 60%;
                          margin-left: 17%;
                          margin-right:20%;
                          margin-top: 5%;
                          border:none;
                          top: 9%;"
                        :style="
                          `background: ${portal_cautivo.formulario.color_formulario};`+
                          `border-radius: ${portal_cautivo.formulario.borde_formulario}px;`+
                          `${portal_cautivo.portal.tipo_fondo == 1 ? 'position: absolute;' : 'width: 62.5%; margin-left: 19.5%; margin-top: 12.7%; display: inline-block;'}`
                        ">
                        <div class="row" style="padding-top: 50px;">
                          <div class="col-md-8 offset-md-2 text-center">
                            <center>
                              <div 
                                id="fotoUsuario"
                                :style="
                                  `width: ${portal_cautivo.logo.tamano_logo}px;`+
                                  `height: ${portal_cautivo.logo.tamano_logo}px;`+
                                  `background:${portal_cautivo.logo.color_fondo};`+
                                  `border-radius: ${portal_cautivo.logo.borde}px;`+
                                  `padding-top: 10px;`
                                "
                              >
                                <img
                                  v-if="!portal_cautivo.logo.logo_cargado"
                                  :src="foto != null ? foto:usuarioicon"
                                  :style="
                                    `width: ${portal_cautivo.logo.tamano_imagen}px;`+
                                    `height: ${portal_cautivo.logo.tamano_imagen}px;`
                                  "
                                  alt="foto"
                                />

                                <img
                                  v-if="portal_cautivo.logo.logo_cargado"
                                  :src="`${API}/hotspot/portales-cautivos/${portal_cautivo.id}/logo?_tk=${tk}`"
                                  :style="
                                    `width: ${portal_cautivo.logo.tamano_imagen}px;`+
                                    `height: ${portal_cautivo.logo.tamano_imagen}px;`
                                  "
                                  alt="foto"
                                />

                              </div>
                            </center>
                          </div>
                        </div>
                        <br>
                        <div class="row">
                          <div class="col-md-12 text-center">
                            <p
                              :style="
                                `color: ${portal_cautivo.logo.color_titulo};`+
                                `font-size: ${portal_cautivo.logo.tamano_texto}px;`
                              "
                            >
                              {{portal_cautivo.logo.titulo}}
                            </p>
                          </div>
                        </div>

                        <br><br>

                        <div class="row text-center">
                          <div class="col-md-4 offset-md-4 text-center">
                            <input
                              type="text"
                              class="form-control"
                              :placeholder="portal_cautivo.formulario.usuario"
                              :style="
                                `color:${portal_cautivo.formulario.color_texto_usuario};`+
                                `background:${portal_cautivo.formulario.color_fondo_usuario};`+
                                `border-radius: ${portal_cautivo.formulario.borde_usuario}px;`+
                                `padding: ${portal_cautivo.formulario.padding_usuario}px;`+
                                `border: ${portal_cautivo.formulario.grosor_borde_usuario}px solid ${portal_cautivo.formulario.color_borde_usuario};`+
                                `-webkit-input-placeholder { color:black; }`
                              "
                            >
                          </div>
                        </div>
                        
                        <div class="row text-center" style="margin-top: 25px;" v-if="portal_cautivo.tipo_autenticacion == 2">
                          <div class="col-md-4 offset-md-4 text-center">
                            <input
                              type="password"
                              class="form-control"
                              :placeholder="portal_cautivo.formulario.clave"
                              :style="
                                `color:${portal_cautivo.formulario.color_texto_clave};`+
                                `background:${portal_cautivo.formulario.color_fondo_clave};`+
                                `border-radius: ${portal_cautivo.formulario.borde_clave}px;`+
                                `padding: ${portal_cautivo.formulario.padding_clave}px;`+
                                `border: ${portal_cautivo.formulario.grosor_borde_clave}px solid ${portal_cautivo.formulario.color_borde_clave};`
                              "
                            >
                          </div>
                        </div>

                        <div class="row" style="margin-top: 25px;">
                          <div class="col-md-12 text-center">
                            <button
                              class="btn btn-success"
                              :style="
                                `background: ${portal_cautivo.formulario.color_boton_login};`+
                                `border: 1px solid ${portal_cautivo.formulario.color_boton_login};`+
                                `border-radius: ${portal_cautivo.formulario.borde_boton_login}px;`+
                                `padding: ${portal_cautivo.formulario.padding_boton_login}px;`+
                                `color: ${portal_cautivo.formulario.color_texto_boton_login};`
                              "
                            >
                              {{portal_cautivo.formulario.boton_login}}
                            </button>
                          </div>
                        </div>

                        <div class="row" style="margin-top: 25px;" v-if="portal_cautivo.portal.inicio_secion_check">
                          <div class="col-md-12 text-center">
                            <button
                              class="btn btn-success"
                              :style="
                                `color: ${portal_cautivo.portal.color_texto_inicio};`+
                                `background: ${portal_cautivo.portal.color_fondo_inicio};`+
                                `border: 1px solid ${portal_cautivo.portal.color_fondo_inicio};`+
                                `border-radius: ${portal_cautivo.portal.borde_inicio}px;`
                              "
                            >Iniciar sesión y recargar saldo</button>
                          </div>
                        </div>

                        <div class="row" style="margin-top: 25px;" v-if="portal_cautivo.portal.boton_whats_check">
                          <div class="col-md-12 text-center">
                            <button
                              class="btn btn-success"
                              :style="
                                `color: ${portal_cautivo.portal.color_texto_whats};`+
                                `background: ${portal_cautivo.portal.color_fondo_whats};`+
                                `border: 1px solid ${portal_cautivo.portal.color_fondo_whats};`+
                                `border-radius: ${portal_cautivo.portal.borde_whats}px;`
                              "
                            >
                              <i class="fas fa-whatsapp"></i> Iniciar sesión con WhatsApp
                            </button>
                          </div>
                        </div>

                        <div class="row" style="margin-top: 25px;" v-if="portal_cautivo.portal.boton_face_check">
                          <div class="col-md-12 text-center">
                            <button
                              class="btn"
                              :style="
                                `color: ${portal_cautivo.portal.color_texto_face};`+
                                `background: ${portal_cautivo.portal.color_fondo_face};`+
                                `border: 1px solid ${portal_cautivo.portal.color_fondo_face};`+
                                `border-radius: ${portal_cautivo.portal.borde_face}px;`
                              "
                            >
                                <i class="fas fa-facebook-square"></i> Iniciar sesión con Facebook
                            </button>
                          </div>
                        </div>
                      </div>

                      <center >
                        <div
                          v-if="
                            portal_cautivo.portal.mostrar_informacion &&
                            portal_cautivo.portal.posicion_informacion_adicional == 2
                          "
                          v-html="portal_cautivo.portal.informacion_adicional"
                          style="width: 500px; margin-top: 50px;"
                        ></div>
                        <div
                          v-if="
                            portal_cautivo.portal.mostrar_imagen_personalizada &&
                            portal_cautivo.portal.posicion_imagen_personalizada == 2
                          "
                          style="width: 500px; margin-top: 50px;"
                        >
                          <img
                            v-if="!portal_cautivo.portal.imagen_personalizada_cargado"
                            :src="img_person"
                            alt="foto"
                            :style="`width: ${portal_cautivo.portal.tamano_imagen_personaliza}%;`"
                          />

                          <img
                            v-if="portal_cautivo.portal.imagen_personalizada_cargado"
                            :src="`${API}/hotspot/portales-cautivos/${portal_cautivo.id}/imagenes/imagen_personalizada?_tk=${tk}`"
                            alt="foto"
                            :style="`width: ${portal_cautivo.portal.tamano_imagen_personaliza}%;`"
                          />
                        </div>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">
              {{modo == 'nuevo' ? 'Nuevo' : 'Editar'}} portal cautivo
            </h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      onclick="return false"
                      @click="modo == 'nuevo' ? guardar() : actualizar()"
                      v-if="!bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="cerrar()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <h4>Nombre del portal</h4>
            <input
              class="form-control"
              v-model="portal_cautivo.nombre"
              placeholder="Ingrese un nombre para el portal"
              name="nombrePortalCautivo"
              ref="nombrePortalCautivo"
            />
            <hr>
            <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  data-bs-toggle="tab"
                  href="#portal"
                  role="tab"
                >
                  <span class="d-block d-sm-none">
                    <i class="fas fa-columns"></i>
                  </span>
                  <span class="d-none d-sm-block">Portal</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-bs-toggle="tab"
                  href="#logo"
                  role="tab"
                >
                  <span class="d-block d-sm-none"
                    ><i class="fas fa-camera-retro"></i
                  ></span>
                  <span class="d-none d-sm-block">Logo</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-bs-toggle="tab"
                  href="#formulario"
                  role="tab"
                >
                  <span class="d-block d-sm-none"
                    ><i class="fas fa-clipboard-list"></i
                  ></span>
                  <span class="d-none d-sm-block">Formulario</span>
                </a>
              </li>
            </ul>
            <!-- Tab panes -->
            <div class="tab-content p-3 text-muted">
              <div class="tab-pane active" id="portal" role="tabpanel">
                <configuracion-portal
                  :portal_uno.sync="portal_cautivo.portal"
                  :img_fondo.sync="img_fondo"
                  :img_person.sync="img_person"
                  :portal_cautivo_uno.sync="portal_cautivo"
                  v-on:cargar-fondo="cargarImagenFondo($event)"
                  v-on:eliminar-fondo="eliminarImagenFondo($event)"
                  v-on:cargar-imagen-personalizada="cargarImagenPersonalizada($event)"
                  v-on:eliminar-imagen-personalizada="eliminarImagenPersonalizada($event)"
                ></configuracion-portal>
              </div>
              <div class="tab-pane" id="logo" role="tabpanel">
                <configuracion-logo
                  :logo_uno.sync="portal_cautivo.logo"
                  :foto.sync="foto"
                  v-on:cargar-logo="cargarLogo($event)"
                  v-on:eliminar-logo="eliminarLogo($event)"
                ></configuracion-logo>
              </div>
              <div class="tab-pane" id="formulario" role="tabpanel">
                <configuracion-formulario 
                  :formulario_uno.sync="portal_cautivo.formulario"
                  :portal_cautivo.sync="portal_cautivo"
                ></configuracion-formulario>
              </div>
            </div>
          </div>
        </div>
        <div class="text-right mb-4">
          <div class="btn-group">
            <button @click="cerrar()" class="btn btn-secondary">
              <i class="mdi mdi-chevron-left"></i>
              Atrás
            </button>
            <button
              type="button"
              class="btn btn-success"
              @click="modo=='nuevo' ? guardar():actualizar()"
              :disabled="bandera_spinner"
            >
              <i
                class="mdi"
                :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
              ></i>
              {{modo=='nuevo' ? 'Guardar':'Actualizar'}}
            </button>
          </div>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import ConfiguracionPortal from './ConfPortal.vue'
import ConfiguracionLogo from './ConfLogo.vue'
import ConfiguracionFormulario from './ConfFormulario.vue'
import PortalCautivoSrv from '@/services/hotspot/PortalCautivoSrv.js'
import usuarioicon from '@/assets/img/usuario.png'
import Layout from "@/views/layouts/main"
import API from '@/API.js'

export default {
  name: 'EdicionPortalCautivoHotspot',
  components : {
    Layout,
    ConfiguracionPortal,
    ConfiguracionLogo,
    ConfiguracionFormulario
  },
  data() {
    return {
      modo: 'nuevo',
      API: API,
      tk: localStorage.getItem('argusblack.token'),
      portal_cautivo: {
        id:null,
        nombre:'',
        tipo_autenticacion: 1,  // 1 = pin, 2 = usuario y clave
        portal:{
          tipo_fuente: 'arial',  // Arial, Calibri, Open sans
          color_texto_inicio: '#ffffff',
          color_fondo_inicio: '#1AB394',
          borde_inicio: 6,

          color_texto_face: '#ffffff',
          color_fondo_face: '#3B5997',
          borde_face: 6,

          color_texto_whats: '#ffffff',
          color_fondo_whats: '#61B752',
          borde_whats: 6,

          primer_color: '#243646',
          segundo_color: '#dedede',

          inicio_secion_check: false,
          boton_whats_check: false,
          boton_face_check: false,

          tipo_fondo: 1,    // 1 = dos colores , 2 = degradado , 3 = imagen de fondo
          fondo_cargado: false,
          informacion_adicional: '',
          posicion_informacion_adicional: 1,       // 1 = arriba, 2 = abajo
          mostrar_informacion: false,

          imagen_personalizada_cargado: false,
          mostrar_imagen_personalizada: false,
          posicion_imagen_personalizada: 1,         // 1 = arriba, 2 = abajo
          tamano_imagen_personaliza: 20,
        },
        logo: {
          titulo: 'Login',
          sin_color_fondo_logo: false,
          color_fondo: '#243646',
          borde: 45,
          tamano_imagen: 300,
          tamano_logo: 320,
          color_titulo: '#787373',
          tamano_texto: 35,
          logo_cargado: false,
        },
        formulario: {
          color_formulario: '#FFFFFF',
          formulario_check: true,
          borde_formulario: 20,

          usuario: 'Usuario',
          color_texto_usuario: '#909395',
          color_fondo_usuario: '#FFFFFF',
          color_borde_usuario: '#E5E6E7',
          borde_usuario: 0,
          padding_usuario: 6,
          grosor_borde_usuario: 1,
          sin_color_fondo_usuario: false,

          clave: 'Clave',
          color_texto_clave: '#909395',
          color_fondo_clave: '#FFFFFF',
          color_borde_clave: '#E5E6E7',
          borde_clave: 0,
          padding_clave: 6,
          grosor_borde_clave: 1,
          sin_color_fondo_clave: false,


          boton_login: 'Ingresar',
          color_boton_login: '#1AB394',
          color_texto_boton_login: '#FFFFFF',
          borde_boton_login: 3,
          padding_boton_login: 6
        }
      },
      configuracion: true,
      usuarioicon: usuarioicon,
      img_fondo: null,
      img_person: null,
      foto: null,
      logo: '',
      fondo: '',
      imagen_personalizada:'',
      bandera_spinner: false
    }
  },

  created: function() {
    var self = this

    // Determinación del modo
    if (self.$route.path.indexOf('portal-cautivo/nuevo') != -1) self.modo = 'nuevo'
    else self.modo = 'edicion'

    if (self.modo == 'edicion') self.cargarPortal()
  },

  methods: {
    actualizar: function() {
      var self = this

      self.bandera_spinner = true

      if(self.portal_cautivo.nombre == ''){
        iu.msg.warning('Ingrese el nombre para el portal cautivo')
        self.$refs.nombrePortalCautivo.focus()
        self.bandera_spinner = false
        return
      }

      var datos = {
        'portal'     : self.portal_cautivo.portal,
        'logo'       : self.portal_cautivo.logo,
        'formulario' : self.portal_cautivo.formulario
      }

      let portal_cautivo = {
        id: self.portal_cautivo.id,
        nombre: self.portal_cautivo.nombre,
        datos: datos,
        tipo_autenticacion: self.portal_cautivo.tipo_autenticacion,
        created_at: self.portal_cautivo.created_at,
        updated_at: self.portal_cautivo.updated_at
      }

      PortalCautivoSrv.actualizar(portal_cautivo).then(response => {
        iu.msg.success('Portal cautivo actualizado')
        
        if(self.foto != null && !self.portal_cautivo.logo.logo_cargado) self.actualizarLogo(self.logo)
        if(self.img_fondo != null && !self.portal_cautivo.portal.fondo_cargado) self.actualizarImagen(self.fondo,'fondo')
        if(self.img_person != null && !self.portal_cautivo.portal.imagen_personalizada_cargado) self.actualizarImagen(self.imagen_personalizada, 'imagen_personalizada')

      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar el portal cautivo'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(fin => {
        self.bandera_spinner = false
      })
    },

    actualizarLogo: function(logo, idPortal = null) {
      var self = this
      
      var idPortal = idPortal != null ? idPortal:self.$route.params.id

      PortalCautivoSrv.actualizarLogo(idPortal,logo).then(response => {
        iu.msg.success('Se actualizo correctamente el logo del portal cautivo')

        self.portal_cautivo.logo.logo_cargado = true
        setTimeout(() => {
          self.actualizar()
        }, 200);
      }).catch(error => {
        let mensaje = error.response.data.message || 'No se pudo acualizar el logo del portal caultivo '+idPortal
        iu.msg.error(mensaje)
        console.log(error)
        self.cargarPortal()
      })
    },

    actualizarImagen(imagen, nombreImagen, idPortal = null){
      var self = this,
          idPortalCautivo = idPortal != null ? idPortal:self.$route.params.id
      
      PortalCautivoSrv.guardarImagen(idPortalCautivo,imagen,nombreImagen).then(response => {
        iu.msg.success('se guardo la imagen '+nombreImagen)
        switch (nombreImagen) {
          case 'fondo':
            self.portal_cautivo.portal.fondo_cargado = true
            setTimeout(() => {
              self.actualizar()
            }, 200);
          break;
          case 'imagen_personalizada':
            self.portal_cautivo.portal.imagen_personalizada_cargado = true
            setTimeout(() => {
              self.actualizar()
            }, 200);
          break;
        }
      })
    },
    
    atras: function() {
      this.$router.go(-1)
    },

    cargarLogo: function(logo){
      let self = this
      var inpFoto = document.createElement("input")
      inpFoto.setAttribute('type', 'file')
      var reader = new FileReader()

      inpFoto.click()

      reader.onload = function(e) {
        self.foto = this.result
      }

      inpFoto.addEventListener('change', function() {
        reader.readAsDataURL(inpFoto.files[0])
        self.logo= inpFoto.files[0]
      })
    },

    cargarImagenFondo: function(){
      let self = this

      var inpFondo = document.createElement("input")
      inpFondo.setAttribute('type', 'file')
      var reader = new FileReader()

      inpFondo.click()

      reader.onload = function(e) {
        self.img_fondo = this.result
      }

      inpFondo.addEventListener('change', function() {
        reader.readAsDataURL(inpFondo.files[0])
        self.fondo= inpFondo.files[0]
      })
    },

    cargarImagenPersonalizada: function(){
      let self = this

      var inpImgPerson = document.createElement("input")
      inpImgPerson.setAttribute('type', 'file')
      var reader = new FileReader()

      inpImgPerson.click()

      reader.onload = function(e) {
        self.img_person = this.result
      }

      inpImgPerson.addEventListener('change', function() {
        reader.readAsDataURL(inpImgPerson.files[0])
        self.imagen_personalizada = inpImgPerson.files[0]
      })
    },

    cargarPortal: function() {
      var self = this
      var idPortal = self.$route.params.id

      PortalCautivoSrv.portalJSON(idPortal).then(response => {
        var portal = response.data

        Object.assign(self.portal_cautivo, {
          id: portal.id,
          nombre: portal.nombre,
          tipo_autenticacion: portal.tipo_autenticacion,
          created_at: portal.created_at,
          updated_at: portal.updated_at
        })

        Object.assign(self.portal_cautivo.portal, JSON.parse(portal.datos).portal)
        Object.assign(self.portal_cautivo.logo, JSON.parse(portal.datos).logo)
        Object.assign(self.portal_cautivo.formulario, JSON.parse(portal.datos).formulario)
      }).catch(error => {
        let mensaje = error.response.data.message || 'No se pudo cargar el portal '+idPortal
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cerrar: function() {
      var self = this
      self.atras()
    },

    eliminarLogo: function() {
      var self = this,
          idPortal = self.$route.params.id

      if(self.modo == 'nuevo') {
        self.portal_cautivo.logo.logo_cargado = false
        self.foto = null
      } else {
        PortalCautivoSrv.eliminarLogo(idPortal).then(response => {
          self.portal_cautivo.logo.logo_cargado = false
          self.foto = null
        }).catch(error => {
          let mensaje = error.response.data.message || 'No se pudo eliminar el logo del portal cautivo'
          iu.msg.error(mensaje)
          console.log(error)
        })
      }
    },

    eliminarImagenFondo: function() {
      var self = this, idPortal = self.$route.params.id

      if(self.modo == 'nuevo') {
        self.portal_cautivo.portal.fondo_cargado = false
        self.img_fondo = null
        self.fondo = ''
      } else {
        PortalCautivoSrv.eliminarImagen(idPortal,'fondo').then(response => {
          self.portal_cautivo.portal.fondo_cargado = false
          self.img_fondo = null
          self.fondo = ''
        }).catch(error => {
          let mensaje = error.response.data.message || 'No se pudo eliminar el fondo de imagen del portal cautivo'
          iu.msg.error(mensaje)
          console.log(error)
        })
      }
    },

    eliminarImagenPersonalizada: function() {
      var self = this, idPortal = self.$route.params.id

      if(self.modo == 'nuevo') {
        self.portal_cautivo.portal.imagen_personalizada_cargado = false
        self.img_person = null
        self.imagen_personalizada = ''
      } else {
        PortalCautivoSrv.eliminarImagen(idPortal,'imagen_personalizada').then(response => {
          self.portal_cautivo.portal.imagen_personalizada_cargado = false
          self.img_person = null
          self.imagen_personalizada = ''
        }).catch(error => {
          let mensaje = error.response.data.message || 'No se pudo eliminar la imagen personalizada del portal cautivo'
          iu.msg.error(mensaje)
          console.log(error)
        })
      }
    },

    guardar: function(){
      var self = this

      self.bandera_spinner = true

      if(self.portal_cautivo.nombre == ''){
        iu.msg.warning('Ingrese el nombre para el portal cautivo')
        self.$refs.nombrePortalCautivo.focus()
        self.bandera_spinner = false
        return
      }

      var datos = {
        'portal'     : self.portal_cautivo.portal,
        'logo'       : self.portal_cautivo.logo,
        'formulario' : self.portal_cautivo.formulario
      }

      let portal_cautivo = {
        nombre: self.portal_cautivo.nombre,
        datos: datos,
        tipo_autenticacion: self.portal_cautivo.tipo_autenticacion,
      }

      PortalCautivoSrv.guardar(portal_cautivo).then(response => {
        let idPortal = response.data
        iu.msg.success('Se guardo correctamente el portal cautivo')

        if (self.$route.query.hotspot) sessionStorage.setItem('argusblack.hotspot.id_portal_cautivo', idPortal)
        
        if(self.foto != null && !self.portal_cautivo.logo.logo_cargado) self.actualizarLogo(self.logo, idPortal)
        if(self.img_fondo != null && !self.portal_cautivo.portal.fondo_cargado) self.actualizarImagen(self.fondo,'fondo',idPortal)
        if(self.img_person != null && !self.portal_cautivo.portal.imagen_personalizada_cargado) self.actualizarImagen(self.imagen_personalizada, 'imagen_personalizada',idPortal)

        self.$router.replace({ name: 'edicionPortalCautivoHotspot', params: { id: idPortal } })
        self.modo = 'edicion'
        self.portal_cautivo.id = idPortal
        
      }).catch(error => {
        let mensaje = error.response.data.message || 'No se pudo guardar el portal cautivo'
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(fin => {
        self.bandera_spinner = false
      })
    }
  },
}
</script>

<style scoped>
.gris-bg {
  background-color: #f3f3f4;
}

@media (max-width: 440px) {
  #portalCautivo {
    zoom: 0.5;
    -moz-transform: scale(0.5); 
    -moz-transform-origin: 0 0;
    -webkit-transform-origin: 0px 0px; /* move the origin to top-left */
  }

  #contenedorFormulario {
    margin-left: 10% !important;
    margin-right: 10% !important;
    margin-top: 17% !important;
  }

  .tamano-vista {
    min-height: 100px !important;
  }
}
</style>