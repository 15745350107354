<template>
  <div class="mt-3">
    <h4><i class="fa fa-cloud"></i> Logo</h4>
    <small>Personalice el portal cautivo.</small>
    <div>
      <div class="d-grid gap-2">
        <button class="btn btn-soft-success bg-gradient" @click="foto==null && !logo.logo_cargado?seleccionarLogo():eliminarLogo()">
          <i class="mdi" :class="foto==null ? 'mdi-upload' : 'mdi-trash-can-outline'"></i>
          {{foto == null && !logo.logo_cargado? 'Seleccionar' : 'Eliminar'}} logo
        </button>
      </div>
      <br>
      <div class="form-check form-switch">
        <input
          class="form-check-input" type="checkbox"
          id="conColorFondoLogo" v-model="logo.sin_color_fondo_logo"
          @change="logo.sin_color_fondo_logo ? logo.color_fondo = 'none': logo.color_fondo = '#243646'"
        />
        <label class="form-check-label" for="conColorFondoLogo">Sin color de fondo</label>
      </div>
      <div class="row">
        <label class="col-md-9 col-7 p-2" for="color">Color de fondo:</label>
        <div class="col-md-3 col-5">
          <input
            type="color" v-model="logo.color_fondo"
            class="form-control"
            :disabled="logo.sin_color_fondo_logo"
          />
        </div>
      </div>
      <br>
      <div class="row">
        <label class="col-md-6 col-6 p-2" for="borde">Borde</label>
        <div class="col-md-6 col-6">
          <input
            id="borde" class="form-control"
            type="range" min="20" max="500"
            v-model="logo.borde"
          />
        </div>
      </div>
      <div class="row">
        <label class="col-md-6 col-6 p-2" for="tamano">Tamaño de imagen</label>
        <div class="col-md-6 col-6">
          <input
            id="tamano" class="form-control"
            type="range" min="100" max="500"
            v-model="logo.tamano_imagen"
          />
        </div>
      </div>
      <div class="row">
        <label class="col-md-6 col-6 p-2" for="tamno-logo">Tamaño de logo</label>
        <div class="col-md-6 col-6">
          <input
            id="tamno-logo" class="form-control"
            type="range" min="100" max="500"
            v-model="logo.tamano_logo"
          />
        </div>
      </div>
    </div>
    <div>
      <div class="row">
        <label class="col-md-3 col-6 p-2" for="titulo">Titulo</label>
        <div class="col-md-9 col-6">
          <input
            type="text" id="titulo"
            class="form-control" placeholder="titulo"
            v-model="logo.titulo"
          />
        </div>
      </div>
      <div class="row">
        <label class="col-md-9 col-6 p-2" for="color">Color titulo:</label>
        <div class="col-md-3 col-6">
          <input
            type="color" v-model="logo.color_titulo"
            class="form-control" style="min-width: 80px;"
          />
        </div>
      </div>
      <div class="row">
        <label class="col-md-6 col-6 p-2" for="tamano-text">Tamaño del texto</label>
        <div class="col-md-6 col-6">
          <input
            id="tamano-texto" type="range"
            min="35" max="70" class="form-control"
            v-model="logo.tamano_texto"
          />
        </div>
      </div>
    </div>
    <br>
    <div>
      <h5>Configuración</h5>
      <div class="small">
        Diseña tu portal caultivo a tu gusto puedes utilizar las herramientas que se encuentran arriba para diseñarlo o
        adecualo a tu preferencia para que puedas implementarlo en tu router de Hotspot.
      </div>
    </div>
  </div>
</template>

<script>
import PortalCautivoSrv from '@/services/hotspot/PortalCautivoSrv.js'
export default {
  name: 'ConfLogo',
  props: ['logo_uno','foto'],
  data: function() {
    return {
      foto_seleccionada: null,
      logo: this.logo_uno
    }
  },
  methods: {
    seleccionarLogo: function() {
      var self = this
      self.$emit('cargar-logo',1)
    },
    eliminarLogo: function() {
      var self = this
      self.$emit('eliminar-logo',1)
    }
  }
}
</script>