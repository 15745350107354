<template>
  <div class="mt-3">
    <h4><i class="fa fa-desktop"></i> Portal</h4>
    <small>Crontrol de portal cautivo.</small>
    
    <div v-if="false">
      <div class="row">
        <label class="col-md-8 p-2">Botón de inicio de sesión y saldo</label>
        <div class="col-md-4 text-right">
          <div class="form-check form-check-right form-switch form-switch-lg">
            <input
              class="form-check-input" type="checkbox"
              id="inicio-sesion" v-model="portal.inicio_secion_check"
            />
            <label class="form-check-label" for="inicio-sesion"></label>
          </div>
        </div>
      </div>
      <div v-if="portal.inicio_secion_check">
        <div class="row">
          <label class="col-md-9 p-2">Color de texto</label>
          <div class="col-md-3">
            <input type="color" v-model="portal.color_texto_inicio" class="form-control">
          </div>
        </div>
        <div class="row">
          <label class="col-md-9 p-2">Color de fondo</label>
          <div class="col-md-3">
            <input type="color" v-model="portal.color_fondo_inicio" class="form-control">
          </div>
        </div>
        <div class="row">
          <label class="col-md-3 p-2">Borde:</label>
          <div class="col-md-9">
            <input class="form-control" type="range" min="1" max="100" v-model="portal.borde_inicio">
          </div>
        </div>
      </div>
    </div>
    <div v-if="false">
      <div class="row">
        <label class="col-md-8 p-2">Botón trial (por medio de WhatsApp)</label>
        <div class="col-md-4 text-right">
          <div class="form-check form-check-right form-switch form-switch-lg">
            <input
              class="form-check-input" type="checkbox"
              id="boton-whats" v-model="portal.boton_whats_check"
            />
            <label class="form-check-label" for="boton-whats"></label>
          </div>
        </div>
      </div>
      <div v-if="portal.boton_whats_check">
        <br>
        <div class="row">
          <label class="col-md-9 p-2" >Color de texto</label>
          <div class="col-md-3">
            <input type="color" v-model="portal.color_texto_whats" class="form-control">
          </div>
        </div>
        <div class="row">
          <label class="col-md-9 p-2">Color de fondo</label>
          <div class="col-md-3">
            <input type="color" v-model="portal.color_fondo_whats" class="form-control">
          </div>
        </div>
        <div class="row">
          <label class="col-md-3 p-2">Borde:</label>
          <div class="col-md-9">
            <input class="form-control" type="range" min="1" max="100" v-model="portal.borde_whats">
          </div>
        </div>
      </div>
    </div>
    <div v-if="false">
      <div class="row">
        <label class="col-md-8 p-2">Botón trial (por medio de Facebook)</label>
        <div class="col-md-4 text-right">
          <div class="form-check form-check-right form-switch form-switch-lg">
            <input
              class="form-check-input" type="checkbox"
              id="boton-face" v-model="portal.boton_face_check"
            />
            <label class="form-check-label" for="boton-face"></label>
          </div>
        </div>
      </div>
      <div v-if="portal.boton_face_check">
        <br>
        <div class="row">
          <label class="col-md-9 p-2">Color de texto</label>
          <div class="col-md-3">
            <input type="color" v-model="portal.color_texto_face" class="form-control">
          </div>
        </div>
        <div class="row">
          <label class="col-md-9 p-2">Color de fondo</label>
          <div class="col-md-3">
            <input type="color" v-model="portal.color_fondo_face" class="form-control">
          </div>
        </div>
        <div class="row">
          <label class="col-md-3 p-2">Borde:</label>
          <div class="col-md-9">
            <input class="form-control" type="range" min="1" max="100" v-model="portal.borde_face" />
          </div>
        </div>
      </div>
    </div>
    <div>
      <span>
        Login con usuario y contraseña
      </span>
      <br>
      <div class="form-check" style="display: inline-block;">
        <input
          class="form-check-input form-check-input-success"
          type="radio" name="filtros" id="tipoUsuario"
          v-model="portal_cautivo.tipo_autenticacion" :value="2"
        />
        <label class="form-check-label" :class="{'text-success': portal_cautivo.tipo_autenticacion == 2}" for="tipoUsuario">
          <strong>Usuario y contraseña</strong>
        </label>
      </div>
      &nbsp;
      <div class="form-check" style="display: inline-block;">
        <input
          class="form-check-input form-check-input-primary"
          type="radio" name="filtros" id="tipoPIN"
          v-model="portal_cautivo.tipo_autenticacion" :value="1"
        />
        <label class="form-check-label" :class="{'text-primary': portal_cautivo.tipo_autenticacion == 1}" for="tipoPIN">
          <strong>PIN</strong>
        </label>
      </div>
      &nbsp;
      <div v-if="portal_cautivo.tipo_autenticacion == 1">
        <strong><span>* Se oculta el password para operar solo con PIN</span></strong>
      </div>
    </div>
    <br>
    <h4><i class="fa fa-desktop"></i> Personalización de fondo</h4>
    <div>
      <label>Tipo:</label>
      <br>
      <div class="text-center">
        <div class="form-check" style="display: inline-block;">
          <input
            class="form-check-input form-check-input-success"
            type="radio" name="fondo"
            id="SDegra"
            v-model="portal.tipo_fondo"
            :value="1"
            @change="
              portal.mostrar_informacion = false,
              portal.mostrar_imagen_personalizada = false
            "
          />
          <label class="form-check-label" :class="{'text-success': portal.tipo_fondo == 1}" for="SDegra">
            <strong>Dos colores</strong>
          </label>
        </div>
        &nbsp;
        <div class="form-check" style="display: inline-block;">
          <input
            class="form-check-input form-check-input-primary"
            type="radio" name="fondo" id="CDegra"
            v-model="portal.tipo_fondo" :value="2"
          />
          <label class="form-check-label" :class="{'text-primary': portal.tipo_fondo == 2}" for="CDegra">
            <strong>Con degradado</strong>
          </label>
        </div>
        &nbsp;
        <div class="form-check" style="display: inline-block;">
          <input
            class="form-check-input form-check-input-info"
            type="radio" name="fondo" id="ImagenFondo"
            v-model="portal.tipo_fondo" :value="3"
          />
          <label class="form-check-label" :class="{'text-info': portal.tipo_fondo == 3}" for="ImagenFondo">
            <strong>Imagen fondo</strong>
          </label>
        </div>
      </div>

      <br><br>

      <div class="row" v-if="portal.tipo_fondo != 3">
        <div class="col-md-6">
          <div class="row">
            <label class="col-sm-6 col-6 p-2" for="color">Primer color:</label>
            <div class="col-sm-6 col-6">
              <input type="color" v-model="portal.primer_color" class="form-control col-xs-3" style="min-width: 80px;">
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <label class="col-sm-7 col-6 p-2" for="color">Segundo color:</label>
            <div class="col-sm-5 col-6">
              <input type="color" v-model="portal.segundo_color" class="form-control col-xs-3" style="min-width: 80px;">
            </div>
          </div>
        </div>
      </div>
      
      <div class="d-grid gap-2">
        <button
          v-show="portal.tipo_fondo == 3"
          class="btn btn-soft-success bg-gradient"
          @click="
            img_fondo==null && !portal.fondo_cargado ? seleccionarImagenFondo() : eliminarImagenFondo()
          "
        >
          <i class="mdi" :class="img_fondo==null && !portal.fondo_cargado ? 'mdi-upload' : 'mdi-trash-can-outline'"></i>
          {{img_fondo == null && !portal.fondo_cargado ? 'Seleccionar' : 'Eliminar'}} imagen de fondo
        </button>
      </div>
    </div>
    
    <h4><i class="fa fa-desktop"></i> Estilo de fuente</h4>
    <div>
      <div class="row">
        <div class="col-md-6 col-6">
          <label>Tipo de fuente:</label>
          <select class="form-select" v-model="portal.tipo_fuente">
            <option value="arial">Arial</option>
            <option value="calibri">Calibri</option>
            <option value="open sans">Open sans</option>
          </select>
        </div>
      </div>
    </div>
    <br>
    
    <h4><i class="fa fa-desktop"></i> Texto personalizado</h4>
    
    <div>
      <div class="row">
        <label class="col-md-8 p-2">Insertar texto personalizado</label>
        <div class="col-md-4 text-right">
          <div class="form-check form-check-right form-switch form-switch-lg">
            <input
              class="form-check-input" type="checkbox"
              id="inttext" v-model="portal.mostrar_informacion"
              :disabled="portal.tipo_fondo == 1"
            />
            <label class="form-check-label" for="inttext"></label>
          </div>
        </div>
      </div>
      <br>
      <div class="form-check" style="display: inline-block;">
        <input
          class="form-check-input form-check-input-success"
          type="radio" name="posicion_info" id="arriba_info"
          v-model="portal.posicion_informacion_adicional" :value="1"
          :disabled="!portal.mostrar_informacion"
        />
        <label class="form-check-label" :class="{'text-success': portal.posicion_informacion_adicional == 1}" for="arriba_info">
          <strong>Arriba</strong>
        </label>
      </div>
      &nbsp;
      <div class="form-check" style="display: inline-block;">
        <input
          class="form-check-input form-check-input-primary"
          type="radio" name="posicion_info" id="abajo_info"
          v-model="portal.posicion_informacion_adicional" :value="2"
          :disabled="!portal.mostrar_informacion"
        />
        <label class="form-check-label" :class="{'text-primary': portal.posicion_informacion_adicional == 2}" for="abajo_info">
          <strong>Abajo</strong>
        </label>
      </div>
      
      <br><br>
      <div class="row">
        <div class="col-md-12">
          <ckeditor
            v-model="portal.informacion_adicional"
            :editor="editor"
            :config="editorConfig"
            :disabled="!portal.mostrar_informacion"
          ></ckeditor>
        </div>
      </div>
    </div>
    <br>
    <h4><i class="fa fa-desktop"></i> Imagen personalizada</h4>
    
    <div>
      <div class="row">
        <label class="col-md-8 p-2">Insertar imagen personalizado</label>
        <div class="col-md-4 text-right">
          <div class="form-check form-check-right form-switch form-switch-lg">
            <input
              class="form-check-input" type="checkbox"
              id="imgPerson" v-model="portal.mostrar_imagen_personalizada"
              :disabled="portal.tipo_fondo == 1"
            />
            <label class="form-check-label" for="imgPerson"></label>
          </div>
        </div>
      </div>
      <div class="form-check" style="display: inline-block;">
        <input
          class="form-check-input form-check-input-success"
          type="radio" name="posicion_img_person" id="arriba_img_person"
          v-model="portal.posicion_imagen_personalizada" :value="1"
          :disabled="!portal.mostrar_imagen_personalizada"
        />
        <label class="form-check-label" :class="{'text-success': portal.posicion_imagen_personalizada == 1}" for="arriba_img_person">
          <strong>Arriba</strong>
        </label>
      </div>
      &nbsp;
      <div class="form-check" style="display: inline-block;">
        <input
          class="form-check-input form-check-input-primary"
          type="radio" name="posicion_img_person" id="abajo_img_person"
          v-model="portal.posicion_imagen_personalizada" :value="2"
          :disabled="!portal.mostrar_imagen_personalizada"
        />
        <label class="form-check-label" :class="{'text-primary': portal.posicion_imagen_personalizada == 2}" for="abajo_img_person">
          <strong>Abajo</strong>
        </label>
      </div>
      <br><br>
      
      <div class="d-grid gap-2">
        <button
          v-show="portal.mostrar_imagen_personalizada"
          class="btn btn-soft-success bg-gradient"
          @click="
            img_person==null && !portal.imagen_personalizada_cargado ? seleccionarImagenPersonalizada() : eliminarImagenPersonalizada()
          "
        >
          <i class="mdi" :class="img_person==null && !portal.imagen_personalizada_cargado ? 'mdi-upload' : 'mdi-trash-can-outline'"></i>
          {{img_person == null && !portal.imagen_personalizada_cargado ? 'Seleccionar' : 'Eliminar'}} imagen de fondo
        </button>
      </div>
      <br>
      <div class="row">
        <label class="col-md-6 p-2" for="tamano_img">Tamaño de imagen</label>
        <div class="col-md-6">
          <input
            id="tamano_img" type="range" class="form-control"
            min="20" max="100" v-model="portal.tamano_imagen_personaliza"
            :disabled="!portal.mostrar_imagen_personalizada"
          />
        </div>
      </div>
    </div>
    <br>
    <div>
      <h4>Configuración</h4>
      <div class="small">
        Diseña tu portal caultivo a tu gusto puedes utilizar las herramientas que se encuentran arriba para diseñarlo o
        adecualo a tu preferencia para que puedas implementarlo en tu router de Hotspot.
      </div>
    </div>
  </div>
</template>

<script>
import CKEditor from "@ckeditor/ckeditor5-vue"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import '@ckeditor/ckeditor5-build-classic/build/translations/es'
export default {
  name: 'ConfPortal',
  components: { ckeditor: CKEditor.component },
  props: [ 'portal_uno', 'img_fondo', 'img_person', 'portal_cautivo_uno'],
  data: function() {
    return {
      foto_seleccionada: null,
      portal: this.portal_uno,
      portal_cautivo: this.portal_cautivo_uno,
      editor: ClassicEditor,
      editorConfig: {
        language: 'es',
        toolbar: {
          items: [
            'heading', '|',
            'alignment', '|',
            'bold', 'italic', '|',
            'link', '|',
            'bulletedList', 'numberedList', '|',
            'outdent', 'indent', '|',
            'blockQuote', '|',
            'insertTable', '|',
            // 'uploadImage',
            'mediaEmbed', '|',
            'undo', 'redo'
          ],
        },
        mediaEmbed: {
          previewsInData: true
        }
      }
    }
  },
  methods: {
    seleccionarImagenFondo: function() {
      var self = this
      self.$emit('cargar-fondo',1)
    },
    eliminarImagenFondo: function() {
      var self = this
      self.$emit('eliminar-fondo',1)
    },
    seleccionarImagenPersonalizada: function() {
      var self = this
      self.$emit('cargar-imagen-personalizada',1)
    },
    eliminarImagenPersonalizada: function() {
      var self = this
      self.$emit('eliminar-imagen-personalizada',1)
    }
  }
}
</script>

<style scoped>
.form-check-input-primary:checked {
  background-color: #3B76E1;
  border-color: #3B76E1;
}
</style>