<template>
  <div class="mt-3">
    <h4>
      <i class="mdi mdi-content-save-edit-outline"></i> Formulario
    </h4>
    <small>Personalice el portal cautivo.</small>
    <div>
      <div class="row">
        <label class="col-md-6 col-8 p-2">Fondo de formulario</label>
        <div class="col-md-6 col-4 text-right">
          <div class="form-check form-check-right form-switch form-switch-lg">
            <input
              class="form-check-input" type="checkbox"
              id="formulario" v-model="formulario.formulario_check"
              @change="restaurarfondo()"
            />
            <label class="form-check-label" for="formulario"></label>
          </div>
        </div>
      </div>
      <div v-if="formulario.formulario_check">
        <div class="row">
          <label class="col-md-9 col-6 p-2" for="color">Color:</label>
          <div class="col-md-3 col-6">
            <input
              type="color" v-model="formulario.color_formulario"
              class="form-control" style="min-width: 80px;"
            />
          </div>
        </div>
        <div class="row">
          <label class="col-md-3 col-5 p-2" for="borde">Borde:</label>
          <div class="col-md-9 col-7">
            <input
              id="borde" type="range"
              min="1" max="100" class="form-control"
              v-model="formulario.borde_formulario"
            />
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div>
      <div class="row">
        <label class="col-md-3 col-4 p-2" for="usuario">Usuario</label>
        <div class="col-md-9 col-8">
          <input
            type="text" id="usuario"
            class="form-control"
            placeholder="Usuario"
            v-model="formulario.usuario"
          />
        </div>
      </div>
      <div class="form-check form-switch">
        <input
          class="form-check-input" type="checkbox"
          id="conColorFondoUsuario" v-model="formulario.sin_color_fondo_usuario" 
          @change="formulario.sin_color_fondo_usuario ? formulario.color_fondo_usuario = 'none':formulario.color_fondo_usuario = '#FFFFFF'"
        />
        <label class="form-check-label" for="conColorFondoUsuario">Sin color de fondo</label>
      </div>
      <br>
      <div class="row">
        <div class="col-md-9 col-6 p-2">
          <label for="color-text">Color de texto</label>
        </div>
        <div class="col-md-3 col-6">
          <input
            type="color" v-model="formulario.color_texto_usuario"
            class="form-control"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-9 col-6 p-2">
          <label for="color-text">Color de fodo</label>
        </div>
        <div class="col-md-3 col-6">
          <input
            type="color" v-model="formulario.color_fondo_usuario"
            class="form-control" :disabled="formulario.sin_color_fondo_usuario"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-9 col-6 p-2">
          <label for="color-text">Color de borde</label>
        </div>
        <div class="col-md-3 col-6">
          <input
            type="color" v-model="formulario.color_borde_usuario"
            class="form-control"
          />
        </div>
      </div>
      <br>
      <div class="row">
        <label class="col-md-3 col-5 p-2" for="borde_usuario">Borde:</label>
        <div class="col-md-9 col-7">
          <input
            type="range" class="form-control"
            id="borde_usuario" min="1" max="50"
            v-model="formulario.borde_usuario"
          />
        </div>
      </div>
      <div class="row">
        <label class="col-md-3 col-5 p-2" for="padding_usuario">Padding:</label>
        <div class="col-md-9 col-7">
          <input
            type="range" class="form-control"
            id="padding_usuario" min="6" max="30"
            v-model="formulario.padding_usuario"
          />
        </div>
      </div>
      <div class="row">
        <label class="col-md-3 col-5 p-2" for="grosor_usuario">Grosor borde:</label>
        <div class="col-md-9 col-7">
          <input
            type="range" class="form-control"
            id="grosor_usuario" min="1" max="10"
            v-model="formulario.grosor_borde_usuario"
          />
        </div>
      </div>
    </div>
    <hr v-if="portal_cautivo.tipo_autenticacion == 2">
    <div v-if="portal_cautivo.tipo_autenticacion == 2">
      <div class="row">
        <label class="col-md-3 col-4 p-2" for="clave">Clave</label>
        <div class="col-md-9 col-8">
          <input type="text" id="clave" class="form-control" placeholder="Clave" v-model="formulario.clave">
        </div>
      </div>
      <div class="form-check form-switch">
        <input
          class="form-check-input" type="checkbox"
          id="conColorFondoClave" v-model="formulario.sin_color_fondo_clave"
          @change="formulario.sin_color_fondo_clave ? formulario.color_fondo_clave = 'none':formulario.color_fondo_clave = '#FFFFFF'"
        />
        <label class="form-check-label" for="conColorFondoClave">Sin color de fondo</label>
      </div>
      <div class="row">
        <div class="col-md-9 col-6 p-2">
          <label for="color-text">Color de texto</label>
        </div>
        <div class="col-md-3 col-6">
          <input type="color" v-model="formulario.color_texto_clave" class="form-control">
        </div>
      </div>
      <div class="row">
        <div class="col-md-9 col-6 p-2">
          <label for="color-text">Color de fodo</label>
        </div>
        <div class="col-md-3 col-6">
          <input type="color" v-model="formulario.color_fondo_clave" class="form-control" :disabled="formulario.sin_color_fondo_clave">
        </div>
      </div>
      <div class="row">
        <div class="col-md-9 col-6 p-2">
          <label for="color-text">Color de borde</label>
        </div>
        <div class="col-md-3 col-6">
          <input type="color" v-model="formulario.color_borde_clave" class="form-control">
        </div>
      </div>
      <div class="row">
        <label class="col-md-3 col-5 p-2" for="borde_clave">Borde:</label>
        <div class="col-md-9 col-7">
          <input
            type="range" class="form-control"
            id="borde_calve" min="1" max="50"
            v-model="formulario.borde_clave"
          />
        </div>
      </div>
      <div class="row">
        <label class="col-md-3 col-5 p-2" for="padding_clave">Padding:</label>
        <div class="col-md-9 col-7">
          <input
            type="range" class="form-control" 
            id="padding_clave" min="6" max="30"
            v-model="formulario.padding_clave"
          />
        </div>
      </div>
      <div class="row">
        <label class="col-md-3 col-5 p-2" for="grosor_clave">Grosor borde:</label>
        <div class="col-md-9 col-7">
          <input
            type="range" class="form-control"
            id="grosor_clave" min="1" max="10"
            v-model="formulario.grosor_borde_clave"
          />
        </div>
      </div>
    </div>
    <hr>
    <div>
      <div class="row">
        <label class="col-md-6 p-2" for="color">Boton de login</label>
        <div class="col-md-6">
          <input
            type="text" id="inicio-sesion"
            class="form-control" placeholder="Inicio de sesión"
            v-model="formulario.boton_login"
          />
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-md-9 col-6 p-2"><label>Color:</label></div>
        <div class="col-md-3 col-6">
          <input
            type="color" v-model="formulario.color_boton_login"
            class="form-control" style="min-width: 80px;"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-9 col-6 p-2"><label>Color de texto:</label></div>
        <div class="col-md-3 col-6">
          <input
            type="color" v-model="formulario.color_texto_boton_login"
            class="form-control" style="min-width: 80px;"
          />
        </div>
      </div>
      <br>
      <div class="row">
        <label class="col-md-3 col-5 p-2">Borde</label>
        <div class="col-md-9 col-7">
          <input
            type="range" class="form-control"
            min="1" max="50"
            v-model="formulario.borde_boton_login"
          />
        </div>
      </div>
      <div class="row">
        <label class="col-md-3 col-5 p-2" for="padding_btn">Padding:</label>
        <div class="col-md-9 col-7">
          <input
            type="range" class="form-control"
            id="padding_btn" min="6" max="30"
            v-model="formulario.padding_boton_login"
          />
        </div>
      </div>
    </div>
    <br>
    <div class="sidebar-content">
      <h5>Configuración</h5>
      <div class="small">
        Diseña tu portal caultivo a tu gusto puedes utilizar las herramientas que se encuentran arriba para diseñarlo o
        adecualo a tu preferencia para que puedas implementarlo en tu router de Hotspot.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfFormulario',
  props: ['formulario_uno', 'portal_cautivo'],
  data:function (){
    return {
      formulario: this.formulario_uno
    }
  },
  methods: {
    restaurarfondo: function () {
      var self = this

      if(!self.formulario.formulario_check){
        self.formulario.color_formulario = 'none'
        self.formulario.borde_formulario = 0
      }else{
        self.formulario.color_formulario = '#FFFFFF'
        self.formulario.borde_formulario = 20
      }
    }
  }
}
</script>

<style>
.form-check-input:checked {
  background-color: #63AD6F;
  border-color: #63AD6F;
}
</style>